import * as d3 from 'd3';
import { ApplicationController } from 'stimulus-use';

export default class extends ApplicationController {
  static values = {
    data: String,
  };

  connect() {
    this.loadGraphs();
  }

  loadGraphs() {
    // PREPARE DATA
    const data = this.setData();

    // TODAY VIEW
    this.setTodayDataInfo(data);

    // PREVIOUS VIEW
    this.setPreviousDataInfo(data);

    // TAB INTERACTIVITY
    this.setTabInteractivity();
  }

  setData() {
    const parseDate = d3.timeParse('%Y-%m-%d');
    const data = JSON.parse(this.dataValue).map((d) => ({
      date: parseDate(d.date.trim()),
      Protein: +d.protein || 0,
      Carbs: +d.carbohydrates || 0,
      Fat: +d.fat || 0,
      Energy: +d.energy_kcal || 0,
    }));
    data.sort((a, b) => a.date - b.date);

    return data;
  }

  setTodayDataInfo(data) {
    const todayData = data[data.length - 1];

    // Nutrient data in grams
    const nutrients = [
      { name: 'Carbs', value: todayData.Carbs, color: '#D8711A' },
      { name: 'Fat', value: todayData.Fat, color: '#D8711A' },
      { name: 'Protein', value: todayData.Protein, color: '#D8711A' },
    ];

    // Nutrient data in kcal (Carbs:4, Protein:4, Fat:9 kcal/g)
    const nutrientsKcal = [
      { name: 'Carbs', value: todayData.Carbs * 4, color: '#D8711A' },
      { name: 'Fat', value: todayData.Fat * 9, color: '#D8711A' },
      { name: 'Protein', value: todayData.Protein * 4, color: '#D8711A' },
    ];

    d3.select('#today-info-date')
      .html(d3.timeFormat('%Y-%m-%d')(todayData.date));
    d3.select('#today-info-energy')
      .html(`${todayData.Energy.toFixed(2)} kcal (${(todayData.Energy * 4.184).toFixed(2)} kJ)`);

    this.drawBarChart('#today-chart-grams', nutrients, 'Grams');
    this.drawBarChart('#today-chart-kcal', nutrientsKcal, 'kcal');
    return { nutrients, nutrientsKcal };
  }

  setPreviousDataInfo(data) {
    const previousData = data.slice(0, data.length - 1);

    if (previousData.length > 0) {
      const kcalAvg = d3.mean(previousData, (d) => d.Energy).toFixed(1);
      const carbsAvg = d3.mean(previousData, (d) => d.Carbs).toFixed(1);
      const fatAvg = d3.mean(previousData, (d) => d.Fat).toFixed(1);
      const proteinAvg = d3.mean(previousData, (d) => d.Protein).toFixed(1);

      document.getElementById('kcal-average').textContent = `${kcalAvg} kcal`;
      document.getElementById('carbs-average').textContent = `${carbsAvg}g`;
      document.getElementById('fat-average').textContent = `${fatAvg}g`;
      document.getElementById('protein-average').textContent = `${proteinAvg}g`;

      // Initial draw with line charts
      this.drawTimeBarChart('#kcal-chart', previousData, (d) => d.Energy, '#D8711A');
      this.drawTimeBarChart('#carbs-chart', previousData, (d) => d.Carbs, '#D8711A');
      this.drawTimeBarChart('#fat-chart', previousData, (d) => d.Fat, '#D8711A');
      this.drawTimeBarChart('#protein-chart', previousData, (d) => d.Protein, '#D8711A');

      // Add click handlers to view toggle buttons
      d3.selectAll('.view-toggle').on('click', (e) => {
        const buttons = document.querySelectorAll('.view-toggle');
        buttons.forEach((button) => {
          if (button !== e.target) {
            button.classList.remove('shadow-sm', 'bg-white');
            button.classList.add('bg-transparent');
          } else {
            button.classList.remove('bg-transparent');
            button.classList.add('shadow-sm', 'bg-white');
          }
        });

        const view = d3.select(e.target).attr('data-view');

        Object.values(this.charts()).forEach((chart) => {
          const { container } = chart;
          d3.select(container).html('');

          if (view === 'all') {
            const dateExtent = d3.extent(previousData, (d) => d.date);
            this.drawTimeBarChart(container, previousData, chart.accessor, '#D8711A', dateExtent);
          } else if (view === 'week') {
            // Get last 7 days range
            const lastDate = previousData[previousData.length - 1].date;
            const sevenDaysAgo = new Date(lastDate);
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
            this.drawTimeBarChart(container, previousData, chart.accessor, '#D8711A', [sevenDaysAgo, lastDate]);
          } else {
            // Month view (default)
            this.drawTimeBarChart(container, previousData, chart.accessor, '#D8711A');
          }
        });
      });
    } else {
      d3.select('#previous-container').html('<p>No previous data available.</p>');
    }
  }

  setTabInteractivity() {
    const todayContainerEl = document.getElementById('today-container');
    const previousContainerEl = document.getElementById('previous-container');
    const todayTab = document.getElementById('today-tab');
    const previousTab = document.getElementById('previous-tab');

    todayTab.addEventListener('click', () => {
      todayContainerEl.style.display = 'block';
      previousContainerEl.style.display = 'none';
      todayTab.classList.remove('bg-transparent');
      todayTab.classList.add('shadow-sm', 'bg-white');
      previousTab.classList.add('bg-transparent');
      previousTab.classList.remove('shadow-sm', 'bg-white');
    });

    previousTab.addEventListener('click', () => {
      previousContainerEl.style.display = 'block';
      todayContainerEl.style.display = 'none';
      todayTab.classList.remove('shadow-sm', 'bg-white');
      todayTab.classList.add('bg-transparent');
      previousTab.classList.remove('bg-transparent');
      previousTab.classList.add('shadow-sm', 'bg-white');
    });
  }

  drawBarChart(container, data) {
    const svg = d3.select(container).append('svg')
      .attr('viewBox', '0 0 1000 800')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('class', 'bar-chart-svg');

    const margin = {
      top: 20, right: 0, bottom: 100, left: 160,
    };
    const width = 1000 - margin.left - margin.right;
    const height = 800 - margin.top - margin.bottom;

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map((d) => d.name))
      .range([0, width])
      .padding(0.5);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([height, 0]);

    // X axis without the line
    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .attr('class', 'axis x-axis')
      .call(d3.axisBottom(x)
        .tickSize(0)
        .tickPadding(40))
      .call((h) => h.select('.domain').remove()) // Remove x-axis line
      .selectAll('text');

    // Y axis with larger text
    g.append('g')
      .attr('class', 'axis y-axis')
      .call(d3.axisLeft(y)
        .ticks(4)
        .tickPadding(20))
      .call((h) => {
        h.select('.domain').style('stroke-width', '4px'); // Make axis line thicker
        h.selectAll('.tick line').style('stroke-width', '8px'); // Make tick lines thicker
      })
      .selectAll('text');

    g.selectAll('rect')
      .data(data)
      .enter().append('rect')
      .attr('x', (d) => x(d.name))
      .attr('y', (d) => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - y(d.value))
      .attr('fill', (d) => d.color);
  }

  drawTimeBarChart(container, data, valueAccessor, color, initialDomain = null) {
    d3.select(container).html('');

    const svg = d3.select(container).append('svg')
      .attr('viewBox', '0 0 1000 400')
      .attr('preserveAspectRatio', 'xMidYMid meet');

    const margin = {
      top: 20, right: 30, bottom: 70, left: 100,
    };
    const width = 1000 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const chartArea = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    svg.append('defs')
      .append('clipPath')
      .attr('id', `clip-${container.substring(1)}`)
      .append('rect')
      .attr('width', width)
      .attr('height', height);

    const x = d3.scaleTime()
      .domain(d3.extent(data, (d) => d.date))
      .range([0, width]);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, valueAccessor)]).nice()
      .range([height, 0]);

    const xAxis = chartArea.append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0,${height})`);

    const yAxis = chartArea.append('g')
      .attr('class', 'axis');

    const barsGroup = chartArea.append('g')
      .attr('clip-path', `url(#clip-${container.substring(1)})`);

    const dragSurface = chartArea.append('rect')
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'transparent')
      .style('cursor', 'grab')
      .style('pointer-events', 'all');

    let startX;
    let startDomain;

    const updateChart = () => {
      const [visibleStart, visibleEnd] = x.domain();
      const visibleData = data.filter((d) => d.date >= visibleStart && d.date <= visibleEnd);

      // Find which chart this is
      const chartInfo = Object.values(this.charts()).find((c) => c.container === container);

      // Update the average span if we found the chart info
      if (chartInfo) {
        const visibleAverage = d3.mean(visibleData, valueAccessor).toFixed(1);
        document.getElementById(chartInfo.averageSpan)
          .textContent = `${visibleAverage} ${chartInfo.unit}`;
      }

      const visibleMax = d3.max(visibleData, valueAccessor);
      y.domain([0, visibleMax]).nice();

      xAxis.call(
        d3.axisBottom(x)
          .ticks(4)
          .tickFormat(d3.timeFormat('%m-%d'))
          .tickPadding(4),
      )
        .selectAll('text');

      yAxis.call(d3.axisLeft(y).ticks(4))
        .selectAll('text');

      // Calculate bar width based on visible time range
      const daysInView = (visibleEnd - visibleStart) / (1000 * 60 * 60 * 24);
      const barWidth = (width / daysInView) * 0.8;

      barsGroup.selectAll('rect').remove();

      barsGroup.selectAll('rect')
        .data(data)
        .enter()
        .append('rect')
        .attr('x', (d) => x(d.date) - barWidth / 2)
        .attr('y', (d) => y(valueAccessor(d)))
        .attr('width', barWidth)
        .attr('height', (d) => height - y(valueAccessor(d)))
        .attr('fill', color);

      const visibleAverage = d3.mean(visibleData, valueAccessor);
      chartArea.select('.average-line')
        .attr('y1', y(visibleAverage))
        .attr('y2', y(visibleAverage));
    };

    const drag = d3.drag()
      .on('start', (event) => {
        startX = event.x;
        startDomain = x.domain();
        dragSurface.style('cursor', 'grabbing');
      })
      .on('drag', (event) => {
        const dx = event.x - startX;
        const domainDuration = startDomain[1] - startDomain[0];
        const pixelToTime = domainDuration / width;
        const timeShift = dx * pixelToTime;

        const newDomain = [
          new Date(startDomain[0] - timeShift),
          new Date(startDomain[1] - timeShift),
        ];

        const [minDate, maxDate] = d3.extent(data, (d) => d.date);
        if (newDomain[0] >= minDate && newDomain[1] <= maxDate) {
          x.domain(newDomain);
          updateChart();
        }
      })
      .on('end', () => {
        dragSurface.style('cursor', 'grab');
      });

    dragSurface.call(drag);

    const average = d3.mean(data, valueAccessor);
    chartArea.append('line')
      .attr('class', 'average-line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', y(average))
      .attr('y2', y(average))
      .attr('stroke', '#000000')
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '6,6');

    if (initialDomain) {
      x.domain(initialDomain);
    } else {
      const lastDate = data[data.length - 1].date;
      const thirtyDaysAgo = new Date(lastDate);
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      x.domain([thirtyDaysAgo, lastDate]);
    }

    // Store scale reference for external access
    // eslint-disable-next-line no-underscore-dangle
    svg.node().__xScale = x;

    updateChart();
  }

  charts() {
    return {
      kcal: {
        container: '#kcal-chart',
        accessor: (d) => d.Energy,
        averageSpan: 'kcal-average',
        unit: 'kcal',
      },
      carbs: {
        container: '#carbs-chart',
        accessor: (d) => d.Carbs,
        averageSpan: 'carbs-average',
        unit: 'g',
      },
      fat: {
        container: '#fat-chart',
        accessor: (d) => d.Fat,
        averageSpan: 'fat-average',
        unit: 'g',
      },
      protein: {
        container: '#protein-chart',
        accessor: (d) => d.Protein,
        averageSpan: 'protein-average',
        unit: 'g',
      },
    };
  }
}
