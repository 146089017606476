import { ApplicationController } from 'stimulus-use';
import { destroy } from '@rails/request.js';

export default class extends ApplicationController {
  static get targets() {
    return [
      'form',
    ];
  }

  static get values() {
    return {
      focus: String,
    };
  }

  static get classes() {
    return ['hidden'];
  }

  toggleButtons() {
    const destroyButton = this.element.querySelector('#destroy_annotation_items');
    const elements = this
      .element
      .querySelectorAll('input[name="annotations_selected_annotation_items[annotation_item_ids]"]:checked');
    if (elements.length > 0) {
      destroyButton.disabled = false;
    } else {
      destroyButton.disabled = true;
    }
  }

  destroyAnnotationItems(event) {
    event.preventDefault();
    const form = event.target.closest('form');
    const confirmMessage = form.dataset.turboConfirm;
    if (window.confirm(confirmMessage)) {
      const { action } = form;
      const elements = this
        .element
        .querySelectorAll('input[name="annotations_selected_annotation_items[annotation_item_ids]"]:checked');
      const values = [];
      elements.forEach((element) => {
        values.push(element.value);
      });

      destroy(action, {
        body: {
          annotations_annotation_items_destroy_form: {
            annotation_item_ids: values,
          },
        },
        responseKind: 'turbo-stream',
      });
    }
  }
}
